import React, { Fragment } from "react"
import { Link } from "gatsby"

import PlaygroundLayout from "../../components/playgroundLayout"
import SEO from "../../components/seo"

const PlayGroundTest = () => (
  <PlaygroundLayout>
    <SEO title="Home" />
    <h1>Playground stuff</h1>
  </PlaygroundLayout>
)

export default PlayGroundTest
